'use client'

import { RiChatNewFill, RiCloseFill } from '@remixicon/react'
import { Button } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Conversations from '@/components/Admin/Conversations/Conversations'

import { DrawerContext } from '@/context/conversationDrawer/drawer.context'
import { generateUUID } from '@/utils'

import { Conversation } from '@/types/chatbot'

interface ConversationsListProps {
  conversations: Conversation[]
  mutateConversations: () => void
  setConversationsSize: React.Dispatch<React.SetStateAction<number>>
  noMoreData?: boolean
  onClose?: () => void
}

const ConversationsList: React.FC<ConversationsListProps> = ({
  conversations,
  mutateConversations,
  setConversationsSize,
  noMoreData,
  onClose,
}) => {
  const { t } = useTranslation()
  const { setSelectedConversation } = useContext(DrawerContext)

  return (
    <div className='size-full bg-surface p-1.5 text-on-surface'>
      <div className='z-50 flex w-full items-center justify-between border-b-2 p-2'>
        <Button
          type='primary'
          className='flex items-center'
          onClick={() => {
            onClose && onClose()
            setSelectedConversation(generateUUID())
          }}
          icon={<RiChatNewFill size={20} />}
        >
          {t('conversations-new')}
        </Button>
        {onClose && (
          <Button
            type='text'
            className='flex items-center justify-center !p-2'
            onClick={onClose}
          >
            <RiCloseFill size={20} />
          </Button>
        )}
      </div>

      <Conversations
        conversations={conversations}
        mutateConversations={mutateConversations}
        setConversationsSize={setConversationsSize}
        noMoreData={noMoreData}
        onClose={onClose}
      />
    </div>
  )
}

export default ConversationsList
